<template>
  <div class="grid l-default" v-auto-animate>
    <AppHeader />
    <slot />
    <PoweredByShackle />
  </div>
</template>

<script lang="ts" setup>
import { useResizeObserver, useThrottleFn } from '@vueuse/core'
import { usePoweredByTheme } from '~/composables/poweredByTheme'
const { setTheme } = usePoweredByTheme()
const layoutWidth = ref(0)
const siteContainer = inject<Ref<HTMLElement>>('siteContainer')
const route = useRoute()

const assignTheme = () => {
  if (route.path !== '/') {
    setTheme('dark')
    nextTick(() =>{
      siteContainer?.value?.classList.remove("app-container");
    })
    return
  }

  setTheme('light')
  nextTick(() =>{
    siteContainer?.value?.classList.add("app-container");
  })
}

onMounted(() => assignTheme())
watch(() => route.path, () => assignTheme())

provide('layoutWidth', layoutWidth)

if (import.meta.client) {
  const throttledLayoutUpdate = useThrottleFn((entries) => {
    const body = entries[0]
    if (body) {
      const { width } = body.contentRect
      layoutWidth.value = width
    }
  }, 500)
  useResizeObserver(document.body, throttledLayoutUpdate)
}

</script>

<style>
.l-default {
  grid-template-columns: 1fr;
  grid-template-rows: 4rem 1fr auto;
  row-gap: 1rem;
  column-gap: 1rem;
  height: 100vh;
}
</style>